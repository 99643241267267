<template>
  <div class="datum">
    <futongtitle class="title" ref="productImage"> </futongtitle>

    <div class="Position-box">
      <!-- 背景 -->
      <div class="datum-banner">
        <img class="bgc" src="../../../../img/xinwenbei.png" alt="" />
        <!-- 内容 -->
        <div class="ceeter c">
          <!-- 左边 -->
          <div class="datum-left">
            <!-- 头部 -->
            <div class="title">
              <div class="title-a">
                <div class="miao-a">{{ jobDetails.name }}</div>
                <div class="xingzi">
                  {{ jobDetails.pay ? getPay(jobDetails.pay) : '' }}
                </div>
              </div>

              <div class="title-a">
                <div class="miao-b">
                  <span v-if="jobDetails.cityName">{{ jobDetails.cityName }} |</span>
                  <span class="cont" v-else>{{ jobDetails.city ? $dictInfo.getCityForId(jobDetails.city): "" }} |</span>
                  {{ jobDetails.edu ? showEdu(jobDetails.edu) : '' }} |
                  {{ jobDetails.exp ? getExp(jobDetails.exp) : '' }}
                </div>
                <div class="xingzi-a">
                  发布时间：{{
                    jobDetails.publishTime
                      ? jobDetails.publishTime.substring(
                          0,
                          jobDetails.publishTime.indexOf(' ')
                        )
                      : ''
                  }}
                </div>
              </div>

              <div class="title-a">
                <div class="miao-c">
                  <div
                    class="ju"
                    v-for="(item, index) in jobDetails.welfare"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>

                <div class="xingzi-b">
                  <img src="@/img/message.png"  style="width: 20px;height: 20px;line-height: 28px;cursor: pointer;"  alt=""  @click="sendMessage"  />
                  <div class="xing-btn" @click="addshou">
                    <img
                      v-if="shou == 1"
                      src="../../../../img/星形 1@3x.png"
                      alt=""
                    />
                    <img
                      v-if="shou == 2"
                      src="../../../../img/shouchuang.png"
                      alt=""
                    />
                    <div class="shou">收藏</div>
                  </div>
                  <el-button
                    @click="deliverResume"
                    style="padding: 0 14px; font-size: 16px; height: 32px"
                    type="primary"
                    :loading="loadingFlag"
                    :icon="deliverResumeIcon"
                    :disabled="deliverResumeFlag"
                    >{{
                      jobDetails.jobApply ? '已投递' : '投递简历'
                    }}</el-button
                  >

                  <!-- <div class="xing-btn btn">
                    <img src="../../../../img/投简历@3x.png" alt="" />
                    <div class="shou zi" @click="deliverResume">{{jobDetails.jobApply ? '已投递' : '投递简历'}}</div>
                  </div> -->
                </div>
              </div>
              <div class="xia"></div>
              <!-- 岗位描述 -->
              <div class="gang">
                <h3 class="gang-z">岗位描述</h3>
                <div class="gang-xian"></div>
                <div
                  style="margin: 20px 0 40px"
                  v-html="jobDetails.intro"
                ></div>

                <div class="orgBox">
                  <h3 class="gang-z">企业基本信息</h3>
                  <div class="gang-xian"></div>
                  <div class="orgInfo">
                    <img
                    v-if="jobDetails.logo && jobDetails.logo != 'null'"
                      class="orgImg"
                      :src="$imgUrl(jobDetails.logo)"
                      alt=""
                    />
                    <div class="orgImgBox" v-else>
                      <span>{{ getFirstWord(jobDetails.orgName) }}</span>
                    </div>
                    <div class="orgDetails">
                      <span
                        @click.stop="toEnterpriseDetails(jobDetails.jobOrgId)"
                        >{{ jobDetails.orgName }}</span
                      >
                      <span
                        >{{
                          jobDetails.orgType
                            ? getOrgType(jobDetails.orgType)
                            : ''
                        }}|{{
                          jobDetails.properties
                            ? getJobOrgType(jobDetails.properties)
                            : ''
                        }}|{{
                          jobDetails.size ? getGm(jobDetails.size) : ''
                        }}</span
                      >
                    </div>
                    <div class="orgUser">
                      <span>联系人: {{ jobDetails.legallyLiablePerson ? jobDetails.legallyLiablePerson.charAt(0) : '' }}经理</span>
                      <!-- <img src="@/assets/home/affiliated-img.png" alt="" /> -->
                    </div>
                  </div>
                </div>

                <!-- 公司地址 -->
                <h3 class="gang-z">公司地址</h3>
                <div class="gang-xian"></div>

                <!-- 地图 -->
                <div id="container">
                  <el-amap
                    v-if="jobDetails.position"
                    class="amap-box"
                    :vid="'amap-vue'"
                    :center="jobDetails.position"
                    :zoom="14"
                  >
                    <el-amap-marker
                      :position="jobDetails.position"
                    ></el-amap-marker>
                    <el-amap-text
                      :text="orgDetails.address"
                      :offset="offset"
                    ></el-amap-text>
                  </el-amap>
                </div>
                <!-- 相关推荐 -->
                <h3 class="gang-z">相关推荐</h3>
                <div class="gang-xian"></div>
                <div class="relevantPositionBox">
                  <div
                    class="relevantItem"
                    v-for="(item, index) in relevantPosition"
                    :key="index"
                  >
                    <div class="positionInfo">
                      <span class="positionName" @click="toDatum(item)">{{ item.name }}</span>
                      <span class="positionPay">{{
                        item.pay ? getPay(item.pay) : ''
                      }}</span>
                    </div>
                    <span style="font-size: 14px">{{ item.orgName }}</span>
                    <div class="positionPerson">
                      <!-- <img src="@/img/圆形 1@3x.png" alt="" /> -->
                      <span>联系人: {{ item.legallyLiablePerson ? item.legallyLiablePerson.charAt(0) : '' }}经理</span>
                      <div class="positionLocal">
                        <i></i>
                        <span>{{ item.city ? $dictInfo.getCityForId(item.city): "" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边 -->
          <div class="datum-rit">
            <div class="job-box">
              <!-- <div class="sider">
                <div class="sign-wrap sign-wrap-v2">
                  <div class="update" @click="update">上传简历一键注册</div>
                </div>
              </div> -->
              <div class="sider-list">
                <div class="sider-box">
                  <h3>看过的职位</h3>
                </div>
                <div class="xian"></div>
                <div class="zhiweis" v-for="(item,index) in postRedCollectList" :key="index" @click="toDatumInfo(item)">
                  <div class="wen-box">
                    <div class="it">{{item.postName ? item.postName : ''}}</div>
                    <div class="qian">{{item.pay ? getPay(item.pay) : ''}}</div>
                  </div>
                  <div class="bosz">{{item.orgName ? item.orgName : ''}}</div>
                </div>
              </div>
              <div class="sider-list about-search">
                <div class="h3">相关搜索</div>
                <div class="keyWordBox">
                  <div
                    class="searchKeyWord"
                    v-for="(item, index) in jobSearchKeyWord"
                    :key="index"
                    @click="searchForKeyWord(item.name)"
                  >
                    <span>{{ item.name }}</span>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footerss class="footer"></footerss>
      </div>
    </div>

    <el-dialog
      :visible.sync="visibleIM"
      width="1040px"
      top="10vh"
      :before-close="handleClose"
    >
      <Notice v-if="visibleIM"></Notice>
    </el-dialog>
  </div>
</template>

<script>
import futongtitle from '../../../../components/hometitle/hometitle.vue'
import footerss from '../../../../components/foot/foot.vue'
import {
  jobPostInfo,
  jobPostRedCollectAdd,
  submitResume,
  jobOrgInfo,
  jobPostType,
  jobSearchPage,
  jobPostSearch,
  jobPostRedCollectList
} from '@/api/position/position'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { mapState } from 'vuex'
import Notice from '@/components/notice/notice.vue'
import { detailTeam } from "@/api/team/team";
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
// import TIM from 'tim-js-sdk'
export default {
  components: {
    futongtitle,
    footerss,
    Notice,
  },
  data() {
    return {
      userId: '',
      deliverResumeIcon: 'el-icon-upload2',
      deliverResumeFlag: false,
      loadingFlag: false,
      shou: 1,
      offset: [0, -50],
      options: [
        {
          value: 'zhinan',
          label: '指南',
          children: [
            {
              value: 'shejiyuanze',
              label: '设计原则',
              children: [
                {
                  value: 'yizhi',
                  label: '一致',
                },
              ],
            },
          ],
        },
      ],
      mock: 0,
      input: '',
      cities: [
        {
          label: '北京',
        },
        {
          label: '上海',
        },
        {
          label: '南京',
        },
        {
          label: '成都',
        },
        {
          label: '深圳',
        },
        {
          label: '广州',
        },
      ],
      label: '',
      value: '',
      // positionId: '', //职位id
      jobDetailsObj: {
        //职位查询参数
        id: '',
        userId: '',
      },
      jobDetails: {}, //职位详情
      orgDetails: {}, //企业详情
      dict: [], //字典
      jobEdu: [], //学历列表
      exp: [], //工作经验
      jobPay: [], //薪资
      jobGm: [], //公司规模
      jobOrgType: [], //单位性质
      tradeTypeList: [], //行业类型列表
      collectionOrReadObj: {
        //收藏或阅读参数
        type: '',
        postId: '',
        userId: '',
        ip: ''
      },
      deliverResumeObj: {
        //简历投递参数
        jobUserId: '',
        jobOrgId: '',
        jobPostId: '',
        jobResumeId: '',
      },
      visibleIM: false,
      jobSearchKeyWord: '', //查询搜索关键字
      relevantPosition: [], //相关推荐职位
      postRedCollectList: [] //看过的职位
    }
  },
  computed: {
    ...mapState(['peopleList']),
  },
  created() {
    this.userId = localStorage.getItem('userId')
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
    this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    this.jobGm = this.dict.filter(i => i.code == 'job_gm')[0].children
    this.jobOrgType = this.dict.filter(
      i => i.code == 'job_org_type'
    )[0].children
    this.jobDetailsObj.id = this.$route.query.id
    this.jobDetailsObj.userId = localStorage.getItem('userId')
    this.collectionOrReadObj.postId = this.$route.query.id
    this.collectionOrReadObj.userId = localStorage.getItem('userId')
    // this.collectionOrReadObj.ip = returnCitySN['cip']
    this.deliverResumeObj.jobUserId = localStorage.getItem('userId')
    this.getJobDetails()
    this.getJobTradeType()
    if(localStorage.getItem("readInfo")){
      this.readInfo = JSON.parse(localStorage.getItem("readInfo"))
    }
    // 进入职位详情增加阅读量
    // this.collectionOrRead()
    // 相关搜索关键字
    this.getJobSearchPage()
    // 看过的职位
    this.getJobPostRedCollectList()

    // // var Ip=returnCitySN['cip']
    // var Ip=returnCitySN
    // console.log(Ip,'ipipipipipip');
  },
  mounted() {
    // // 地图
    // var map = new AMap.Map('container', {
    //   zoom: 16,
    //   center: [114.31667, 30.51667], //中心点坐标
    // })
    // //  标记
    // var marker = new AMap.Marker({
    //   position: [114.31667, 30.51667], //位置
    // })
    // map.add(marker) //添加到地图
    // this.$refs.productImage.getindex(1)
    this.clearMapStore()
  },
  methods: {
    clearMapStore(){
    Object.keys(localStorage).forEach(item =>
         item.indexOf('_AMap') !== -1 ? localStorage.removeItem(item) : ''
     );
  },
    // 查看职位详情
    toDatum(item) {
      this.$router.push({
        path: '/datum',
        query: { id: item.id }
      })
      this.$router.go(0)
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
        path: '/datum',
        query: { id: item.postId },
      })
      this.$router.go(0)
    },
    // 看过的职位
    getJobPostRedCollectList() {
      let params = {
        type: '1',
        pageNo: 1,
        pageSize: 3
      }
      this.userId ? params.userId = this.userId : params.ip = returnCitySN['cip']
      jobPostRedCollectList(params).then(res => {
        this.postRedCollectList = res.data.rows
      })
    },
    // 相关推荐
    search(positionType,id) {
      let params = {
        grounding: 'Y',
        auditStatus: 2,
        pageNo: 1,
        pageSize: 10,
        positionType: positionType,
      }
      jobPostSearch(params).then(res => {
        this.relevantPosition = res.data.rows.filter(i => i.id != id)
      })
    },
    // 查询搜索关键字
    getJobSearchPage() {
      jobSearchPage({ pageSize: 10 }).then(res => {
        this.jobSearchKeyWord = res.data.rows
      })
    },
    // 相关搜索
    searchForKeyWord(keyWord) {
      this.$router.push({
        path: '/position',
        query: { keyWord: keyWord }
      })
    },
    handleClose() {
      this.visibleIM = false
    },
    // 发送消息
    async sendMessage() {
      if (!localStorage.getItem('userId')) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      // let userAuth = ''
      // let userTeam = ''
    let res = await getJobUserAuth()
    let userAuth = res.data
    let jobUserId = localStorage.getItem("userId");
    let info = await detailTeam({ jobUserId })
    let userTeam = info.data
      if(userAuth != null || userTeam != null && userTeam.auditStatus == 2){
        this.visibleIM = true
      if (
        this.peopleList.find(i => i.userProfile.userID == this.orgDetails.id)
      ) {
        return
      }
      let peopleList = this.peopleList
      let obj = {
        userProfile: {
          userID: this.orgDetails.id,
          nick: this.orgDetails.name,
        },
      }
      this.peopleList.unshift(obj)
      this.$store.commit('set_peopleList', peopleList)
      }else{
        this.$message.warning('请认证(个人/班组)后进行沟通')
      }
      // let router = this.$router.resolve({path:'/notice',query: {id: this.orgDetails.id, nick: this.orgDetails.name}})
      // window.open(router.href,'_blank')
    },
    // // 发送自定义消息
    // createMessage(resumeId) {
    //   let that = this
    //   let message = this.$tim.createCustomMessage({
    //   // let message = this.$tim.createTextMessage({
    //     to: that.jobDetails.jobOrgId,
    //     conversationType: TIM.TYPES.CONV_C2C,
    //     payload: {
    //       // text: 'recruit'
    //       data: 'recruit', //招聘信息
    //       description: `${resumeId}投递了简历`,
    //       extension: '11'
    //     }
    //   })
    //   // 发送消息
    //   let promise = this.$tim.sendMessage(message)
    //   promise
    //     .then(function (imResponse) {
    //       console.log('自定义消息发送成功', imResponse)
    //       that.visibleIM = true
    //     })
    //     .catch(function (imError) {
    //       console.warn('sendMessage error:自定义消息发送失败', imError)
    //     })
    // },
    // 简历投递
    // deliverResume() {
    //   this.createMessage('123312')
    // },
    deliverResume() {
      if (!this.userId) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      this.deliverResumeFlag = true
      // 先查询简历获取简历id
      if (this.jobDetails.jobApply == null) {
        this.loadingFlag = true
        detailResume({ JobUserId: localStorage.getItem('userId') }).then(
          res => {
            if (res.data) {
              this.deliverResumeObj.jobResumeId = res.data.id
              submitResume(this.deliverResumeObj).then(res => {
                if (!res.success) {
                  this.deliverResumeFlag = false
                  return
                }
                this.loadingFlag = false
                // 投递成功重新查询该职位
                this.getJobDetails()
                this.$message({
                  message: '投递简历成功',
                  type: 'success',
                })
              })
            } else {
              this.$confirm('暂无简历,请前往个人中心填写!', '', {
              confirmButtonText: '去填写',
              showCancelButton: false,
              type: 'warning'
              }).then(() => {
               this.$router.push('/personalCenter/resumeManagement')
              }).catch(() => {});
              this.loadingFlag = false
              this.deliverResumeFlag = false
            }
          }
        ).catch(error=>{
          this.$confirm('暂无简历,请前往个人中心填写!', '', {
              confirmButtonText: '去填写',
              showCancelButton: false,
              type: 'warning'
              }).then(() => {
               this.$router.push('/personalCenter/resumeManagement')
              }).catch(() => {});
          this.loadingFlag = false
          this.deliverResumeFlag = false
        })
      }
    },
    // 阅读量1 收藏2 取消收藏3
    collectionOrRead() {
      jobPostRedCollectAdd(this.collectionOrReadObj).then(res => {
        // 如果type=2，收藏
        if (this.collectionOrReadObj.type == '2') {
          this.shou = 2
        } else if (this.collectionOrReadObj.type == '3') {
          this.shou = 1
        }
      })
    },
    //行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = this.hasChildren(res.data[0].children)
      })
    },
    // 级联选择器递归判断children有没有值
    hasChildren(list) {
      list.forEach(item => {
        if (item.children.length > 0) {
          this.hasChildren(item.children)
        } else {
          item.children = null
        }
      })
      return list
    },
    getPay(code) {
      return this.jobPay.filter(i => i.code == code)[0].name
    },
    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter(i => i.code == val)[0].name
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter(i => i.code == val)[0].name
    },
    // 职位详情
    getJobDetails() {
      jobPostInfo(this.jobDetailsObj).then(res => {
        this.jobDetails = res.data
        this.getJobOrgInfo()
        this.jobDetails.welfare = this.jobDetails.welfare.split(',')
        // 简历投递的职位id
        this.deliverResumeObj.jobPostId = this.jobDetails.id
        // 简历投递的企业id
        this.deliverResumeObj.jobOrgId = this.jobDetails.jobOrgId
        // 更改收藏显示图标
        if (this.jobDetails.collect) {
          this.shou = 2
        } else {
          this.shou = 1
        }
        // 更改投递图标
        if (this.jobDetails.jobApply) {
          this.deliverResumeIcon = 'el-icon-check'
          this.deliverResumeFlag = true
        }
        // 如果this.jobDetails.read是false，增加一次阅读量，true表示该用户看过这条数据
        if (this.jobDetails.read === false && this.userId) {
          this.collectionOrReadObj.type = '1'
          this.collectionOrRead()
        } else if(this.jobDetails.read === false && !this.userId) {
          this.collectionOrReadObj.ip = returnCitySN['cip']
          this.collectionOrReadObj.type = '1'
          this.collectionOrRead()
        }

        this.search(this.jobDetails.positionType,this.jobDetails.id)
        // 地图坐标点
        this.jobDetails.position = [
          this.jobDetails.longitude,
          this.jobDetails.latitude,
        ]
      })
    },
    // // 企业信息
    getJobOrgInfo() {
      jobOrgInfo({ id: this.jobDetails.jobOrgId }).then(res => {
        this.orgDetails = res.data
      })
    },
    // 公司行业回显
    getOrgType(str) {
      let newStr = str.split(',')
      let arr = []
      if (this.tradeTypeList.length > 0) {
        newStr.forEach(i => {
          let ids = this.getOrgTypeForTreer(this.tradeTypeList, i)
          if (ids.length > 0) {
            arr.push(ids)
          }
        })
      }
      let strs = arr.join('，')
      return strs.length > 30 ? strs.substring(0, 30) + '…' : strs
    },
    // 根据公司行业id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          ids.push(list[i].title)
          break
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id)
          if (ids.length > 0) return ids
        }
      }
      return ids
    },
    //进入找企业详情
    toEnterpriseDetails(id) {
      this.$router.push({
        path: '/enterpriseDetails',
        query: { id: id }
      })
    },

    // 如果没企业头像显示企业名称首字母
    getFirstWord(name) {
      if (!name) {
        return
      }
      let pinyin = require('js-pinyin')
      pinyin.setOptions({ checkPolyphone: false, charCase: 0 })
      let word = pinyin.getFullChars(name).substring(0, 1)
      return word
    },
    // 单位性质回显
    getJobOrgType(code) {
      return this.jobOrgType.filter(i => i.code == code)[0].name
    },
    getGm(code) {
      return this.jobGm.filter(i => i.code == code)[0].name
    },

    goxi() {},
    // 收藏职位
    async addshou() {
      if (!this.userId) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      if (this.shou == 2) {
        this.collectionOrReadObj.type = '3'
        await this.collectionOrRead()
        await this.getJobDetails()
        // setTimeout(() => {
        //   this.getJobDetails()
        // }, 500)
      } else {
        this.collectionOrReadObj.type = '2'
        await this.collectionOrRead()
        await this.getJobDetails()
        // setTimeout(() => {
        //   this.getJobDetails()
        // }, 500)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.orgInfo {
  display: flex;
  margin: 30px 0 50px 0;
  .orgImg {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 20px;
  }
  .orgImgBox {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 2px solid #ccc;
    background-color: #f4f4f4;
    margin-right: 20px;
    span {
      font-size: 24px;
      color: #ccc;
      font-weight: bolder;
    }
  }
  .orgDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    span:nth-child(1) {
      font-size: 18px;
      cursor: pointer;
    }
    span:nth-child(1):hover {
      text-decoration: underline;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #999;
    }
  }
  .orgUser {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    span {
      font-size: 12px;
      color: #666;
    }
    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-left: 20px;
    }
  }
}

.Position-box {
  margin-top: 80px;
}

.c {
  width: 1200px;
  margin: 0 auto;
}

#container {
  // width: 841px;
  width: 100%;
  height: 332px;
  margin-top: 11px;
}

.Position-item {
  width: 100%;
  // height: 146px;
  height: 130px;
  background: rgba(255, 255, 255, 1);

  .Position-title {
    width: 1920px;
    padding-top: 36px;
    height: 110px;
    background: rgba(255, 255, 255, 1);

    border-radius: 5px;
    margin: 0 auto;
    .titles {
      display: flex;
      width: 894px;
      height: 48px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(2, 154, 255, 1);
      border-radius: 5px;
      margin-left: 367px;
    }
    ::v-deep .el-input__inner {
      height: 44px;
      line-height: 44px;
      border: none;
    }

    .ipt {
      height: 48px;
      ::v-deep .el-input__inner {
        margin-top: 5px;
        height: 35px;
        line-height: 48px;
        width: 300px;
        border-radius: 0;
      }
    }
  }
}
::v-deep .el-select {
  height: 48px;
  line-height: 48px;
  width: 100px;
}
.city-sel {
  position: relative;
  cursor: pointer;
  width: 200px;
  height: 28px;
}
.city-text {
  width: 68px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 48px;
  margin-left: 20px;
}

.icon-arrow-down {
  position: absolute;
  right: 7px;
  top: 15px;
  display: block;
  width: 16px;
  height: 16px;
}
.fiex {
  display: flex;
}
.ziwei {
  width: 1184px;
  display: flex;
  color: rgb(153, 153, 153);
  font-size: 12px;
  margin-left: 366px;
  justify-content: space-between;
  margin-top: 15px;
}
.btn {
  width: 282px;
  height: 48px;
  margin-left: 20px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

// 背景
.datum-banner {
  .bgc {
    width: 100%;
    height: 200px;
  }
}
.datum-rit {
  margin-left: 10px;
}
// 模态窗
.mock {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #252830;
  background: rgba(37, 40, 48, 0.7);
  z-index: 1002;
  .mock-box {
    width: 786px;
    height: 527px;
    margin: -243px 0 0 -393px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    z-index: 1003;
  }
}
// 内容
.ceeter {
  justify-content: space-between;
  display: flex;
  margin-top: -64px;
  margin-bottom: 100px;

  .datum-left {
    width: 894px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
    .title {
      .title-a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .miao-a {
        margin-top: 23px;
        margin-left: 29px;
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
      }
      .miao-b {
        margin-top: 23px;
        margin-left: 29px;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
      }
      .miao-c {
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-top: 15px;
        width: 590px;
        .ju {
          padding: 0 10px;
          height: 32px;
          border: 1px solid rgba(11, 151, 244, 1);
          border-radius: 5px;
          text-align: center;
          line-height: 32px;
          color: rgba(11, 151, 244, 1);
          // margin-top: 15px;
          margin-left: 10px;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
      .xingzi {
        margin-top: 23px;
        margin-right: 29px;
        color: rgba(255, 141, 25, 1);
        font-size: 26px;
      }
      .xingzi-a {
        margin-top: 23px;
        margin-right: 29px;
        color: rgba(128, 128, 128, 1);
        font-size: 14px;
        margin-top: 15px;
      }
      .xingzi-b {
        display: flex;
        align-items: center;
        margin-right: 28px;
        margin-top: 15px;
        .el-icon-chat-dot-square {
          font-size: 24px;
          color: #0099ff;
          cursor: pointer;
        }
        .xing-btn {
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 32px;
          // width: 120px;
          margin: 0 26px;
          height: 32px;
          cursor: pointer;
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .shou {
            color: rgba(10, 150, 243, 1);
          }
        }
        .btn {
          margin-left: 10px;
          .zi {
            color: #fff;
          }
          background-color: #0099ff;
          border-radius: 4px;
        }
      }
      .xia {
        margin: 30px auto;
        width: 822px;
        height: 0px;
        border: 1px solid rgba(216, 216, 216, 1);
      }
    }
    .gang {
      padding-bottom: 100px;
      margin-left: 30px;
      margin-right: 30px;
      padding-bottom: 50px;
      .gang-z {
        height: 18px;
        color: rgba(51, 51, 51, 1);
        font-size: 20px;
        line-height: 18px;
      }
      .gang-xian {
        width: 40px;
        height: 4px;
        background: rgba(255, 141, 25, 1);
      }
      .gang-zhi {
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
        line-height: 28px;
      }
      .iu {
        margin-top: 24px;
      }
      .zhi {
        margin-top: 50px;
      }
    }
  }

  .datum-rit {
    width: 282px;
    height: 100px;
    border-radius: 3px;
  }
}

.relevantPositionBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .relevantItem {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .positionInfo {
      display: flex;
      margin-bottom: 10px;
      .positionName {
        font-size: 18px;
        font-weight: bolder;
      }
      .positionName:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      .positionPay {
        margin-left: auto;
        margin-right: 0;
        font-size: 20px;
        color: #ff8d19;
      }
    }
    .positionPerson {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: contain;
        margin-right: 4px;
      }
      span {
        font-size: 14px;
        color: #808080;
      }
      .positionLocal {
        margin-left: auto;
        margin-right: 0;
        // span {
        //   color: #808080;
        // }
      }
    }
  }
}

// 右边、
.job-box {
  float: right;
  width: 284px;
}
// 上传简历一键注册
.sign-wrap {
  width: 282px;
  height: 78px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding-top: 30px;
  margin-bottom: 23px;

  .update {
    margin-left: 24px;
    width: 230px;
    height: 36px;
    background: rgba(2, 154, 255, 1);
    border-radius: 3px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}

.sider-list {
  background: #fff;
  margin-bottom: 16px;
  .sider-box {
    width: 282px;
    height: 48px;
    background: rgba(255, 255, 255, 1);
    border-radius: 3px, 3px, 0px, 0px;
    h3 {
      color: rgba(2, 154, 255, 1);
      font-size: 14px;
      line-height: 48px;
      margin-left: 24px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .xian {
    width: 282px;
    height: 0px;
    border: 1px solid rgba(232, 232, 232, 1);
  }
  .zhiweis {
    width: 282px;
    height: 82px;
    .wen-box {
      margin-top: 13px;
      display: flex;
      justify-content: space-around;
      .it {
        width: 125px;
        height: 21px;
        color: rgba(64, 64, 64, 1);
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      :hover.it {
        color: rgba(51, 133, 255, 1);
      }
      .qian {
        color: rgba(255, 141, 25, 1);
        font-size: 14px;
      }
    }
    .bosz {
      margin-left: 24px;
      margin-top: 9px;
      color: rgba(168, 168, 168, 1);
      font-size: 12px;
    }
  }
}
// tab
.about-search {
  .h3 {
    height: 48px;
    line-height: 48px;
    font-size: 15px;
    color: #1592ea;
    padding-left: 24px;
    font-weight: 400;
    border-bottom: solid 2px #f2f2f5;
  }
  .keyWordBox {
    padding: 0 24px;
    .searchKeyWord {
      height: 56px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        flex: 1;
        font-size: 14px;
      }
    }
    .searchKeyWord:nth-last-child(1) {
      border-bottom: none;
    }
    .searchKeyWord:hover {
      color: #1592ea;
    }
  }
}
</style>
